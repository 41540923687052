<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	props: ['arce'],

	data() {
		return {
			loader: false
		}
	},

	computed: {
		groupStructure() {
			return this._groupStructure(this.arce.groupId)
		},
		...mapGetters({
			_groupStructure: 'arceClients/groupStructure',
		})
	},

	methods: {
		childAdded(id) {
			return this.arce.children.some(ch => ch.id == id)
		},
		add(client) {
			let vm = this
			this.loader = true
			setTimeout(() => {
				vm._setChild(client)
				vm._updateArce({...vm.arce})
				vm.loader = false
			}, 3000);
			this.showSteps('stepsArceDetails', 5)

		},
		remove(client) {
			let vm = this
			this.loader = true
			setTimeout(() => {
				vm._removeChild(client)
				vm._updateArce({...vm.arce})
				vm.loader = false
			}, 3000);
			this.showSteps('stepsArceDetails', 5)

		},
		loading() {
			this.loader = true
			setTimeout(() => {
				this.loader = false
			}, 3000);
		},
		closeModal() {
			this.showSteps('stepsArceDetails', 6)
		},
		...mapMutations({
			_setChild: 'arceDetails/setChild',
			_removeChild: 'arceDetails/removeChild',

		}),
		...mapActions({
			_updateArce: 'arceDetails/updateArce'
		})
	},

	mounted() {
		$('#groupStructure').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#groupStructure').off('hide.bs.modal', this.closeModal)
	}
}
</script>
<template>
	<!-- modal estructura del grupo -->
	<div class="modal s-modal fade" id="groupStructure" tabindex="-1" role="dialog" aria-labelledby="groupStructureLabel" aria-hidden="true">
		<div class="modal-dialog modal-xl " role="document">
			<div class="modal-content position-relative">
				<div class="modal-header">
					<h5 class="modal-title text-center w-100" id="groupStructureLabel">Añadir / Eliminar Elementos</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<svg-icon name="close" />
					</button>
				</div>
				<div class="modal-body p-0 ">
					<!-- <pre>{{groupStructure}}</pre> -->
					<div>
						<table class="w-100 s-table" data-v-step="ad4">
							<thead>
								<tr>
									<th>Estructura del Grupo</th>
									<th>Tipología</th>
									<th>Portafolio</th>
									<th>Dispuesto</th>
									<th>Fecha de EEFF</th>
									<th>EEFF</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr class="bg-light">
									<td>
										{{groupStructure.name}}
									</td>
									<td>
										{{groupStructure.type}}
									</td>
									<td>
										Enterprises
									</td>
									<td>
										
									</td>
									<td>

									</td>
									<td>

									</td>
									<td>
										<button class="s-btn s-btn--outline py-1 px-2">
											<svg-icon name="dash" />
										</button>
									</td>
								</tr>
								<tr v-for="(client, index) in groupStructure.children" :key="`c_${index}`" :class="{'bg-light':childAdded(client.id)}">

									<td>
										{{client.name}}
									</td>
									<td>
										{{client.type}}
									</td>
									<td>
										Enterprises
									</td>
									<td>
										
									</td>
									<td>

									</td>
									<td>

									</td>
									<td>
										<button v-if="!childAdded(client.id)" data-v-step="ad5" class="s-btn s-btn--outline py-1 px-2" @click="add(client)">
											<svg-icon name="add" />
										</button>
										<button v-else class="s-btn s-btn--outline py-1 px-2" data-v-step="ad5" @click="remove(client)">
											<svg-icon name="dash" />
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" data-v-step="ad6">Close</button>
				</div>
				<div v-if="loader" class="s-modal__loader">
					<img class="m-auto gray" src="@/assets/simulator/loader.gif" alt="">
				</div>
			</div>
		</div>
	</div>
</template>
